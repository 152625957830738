import dynamic from 'next/dynamic'

import { type SanityModuleItem } from '@data/sanity/queries/types/modules'
const ExternalLinkModule = dynamic(() => import('./external-link'))
const ProductModule = dynamic(() => import('./product'))
const VideoModule = dynamic(() => import('./video'))
const SliderModule = dynamic(() => import('./slider'))
const NewsletterModule = dynamic(() => import('./newsletter'))

interface ModuleProps {
  module: SanityModuleItem
}

const Module = ({ module }: ModuleProps) => {
  switch (module._type) {
    case 'externalLink': {
      const { url, title, description, image } = module

      return (
        <ExternalLinkModule
          url={url}
          title={title}
          description={description}
          image={image}
        />
      )
    }
    case 'product': {
      const { url, title, price, image } = module

      return (
        <ProductModule
          url={url}
          title={title}
          price={price}
          image={image}
          // Image size determines total width of product module.
          // Value is based on container width (448), horzontal padding (20), gap (10) and product count in row (2):
          // (448 - 20 * 2 - 10) / 2
          imageSize={199}
        />
      )
    }

    case 'video': {
      const { title, type, muxVideo, youtubeVideoUrl } = module

      return (
        <VideoModule
          title={title}
          type={type}
          muxVideo={muxVideo}
          youtubeVideoUrl={youtubeVideoUrl}
        />
      )
    }

    case 'slider': {
      const { type, items } = module

      return <SliderModule type={type} items={items} />
    }

    case 'newsletter': {
      const { service, listId } = module

      return <NewsletterModule service={service} listId={listId} />
    }
  }
}

export default Module
