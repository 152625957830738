import groq from 'groq'

import { imageFragment } from './image'

// External link module
const externalLinkModuleFragment = groq`
  url,
  title,
  description,
  image {
    ${imageFragment}
  },
`

// Product module
const productModuleFragment = groq`
  url,
  title,
  price,
  image {
    ${imageFragment}
  },
`

// Video module
const videoModuleFragment = groq`
  title,
  type,
  muxVideo {
    asset->,
  },
  youtubeVideoUrl,
`

// Slider module
const sliderModuleFragment = groq`
  type,
  type == "product" => {
    "items": products[] {
      ${productModuleFragment}
    },
  },
  type == "video" => {
    "items": videos[] {
      ${videoModuleFragment}
    },
  },
`

// Newsletter module
const newsletterModuleFragment = groq`
  service,
  listId,
`

const moduleItemFragment = groq`
  _key,
  _type,
  _type == "externalLink" => {
    ${externalLinkModuleFragment}
  },
  _type == "product" => {
    ${productModuleFragment}
  },
  _type == "video" => {
    ${videoModuleFragment}
  },
  _type == "slider" => {
    ${sliderModuleFragment}
  },
  _type == "newsletter" => {
    ${newsletterModuleFragment}
  },
`

export const moduleFragment = groq`
  _key,
  heading,
  description,
  items[] {
    ${moduleItemFragment}
  },
`
