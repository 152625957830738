import Icon from './icon'

import { type SanitySocialLink } from '@data/sanity/queries/types/settings'

type SocialIconProps = Pick<SanitySocialLink, 'socialNetwork' | 'url' | 'email'>

const SocialIcon = ({ socialNetwork, url, email }: SocialIconProps) => {
  const href = socialNetwork === 'Email' ? `mailto:${email}` : url

  return (
    <a
      href={href}
      target="_blank"
      id={`social-link-${socialNetwork?.toLowerCase()}`}
    >
      {!!socialNetwork && (
        <Icon
          id={`social-link-${socialNetwork?.toLowerCase()}`}
          name={socialNetwork}
          className="w-7 h-7"
        />
      )}
    </a>
  )
}

export default SocialIcon
