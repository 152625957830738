import groq from 'groq'

import { imageFragment } from './image'
import { moduleFragment } from './modules'

export const builderQuery = groq`
  {
    "header": *[
      _type == "header" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0] {
      profilePhoto {
        ${imageFragment}
      },
      type,
      brandDescription,
    },
    "modules": *[
      _type == "modules" &&
      locale == $locale
    ]
      | order(_updatedAt desc)
    [0].modules[] {
      ${moduleFragment}
    },
  }
`
