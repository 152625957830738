import { type GetStaticPropsResult } from 'next'
import dynamic from 'next/dynamic'

import { builderQuery } from '@data/sanity/queries/builder'
import { type SanityBuilderQuery } from '@data/sanity/queries/types/builder'
import { type SanitySettingsQuery } from '@data/sanity/queries/types/settings'
import {
  type BasePageProps,
  type CustomStaticPropsContext,
  getNonNullValues,
  getPagePropsFromContext,
  getPageSanityClient,
} from '@lib/page'
import { getBuilder } from '@lib/sanity/builder'
import { getSettings } from '@lib/sanity/settings'
import { getThemeBackgroundColor } from '@lib/theme'

import Content from '@components/content'
import Layout from '@components/layout'

const PreviewContent = dynamic(() => import('@components/preview-content'))

type HomePageProps = BasePageProps & {
  builder: SanityBuilderQuery
  settings: SanitySettingsQuery
}

const HomePage = ({ draftMode, locale, builder, settings }: HomePageProps) => {
  return (
    <Layout
      brandName={settings.profileSettings.brandName}
      headerPhoto={builder.header.profilePhoto}
      privacySettings={settings.privacySettings}
      theme={settings.theme}
      draftMode={draftMode}
    >
      {!draftMode && <Content builder={builder} settings={settings} />}
      {!!draftMode && (
        <PreviewContent
          builder={builder}
          settings={settings}
          query={builderQuery}
          params={{
            locale,
          }}
        />
      )}
    </Layout>
  )
}

export async function getStaticProps(
  context: CustomStaticPropsContext,
): Promise<GetStaticPropsResult<HomePageProps>> {
  const sanityClient = getPageSanityClient(!!context.draftMode)
  const builder = await getBuilder(sanityClient, context.locale)
  const settings = await getSettings(sanityClient, context.locale)

  if (!builder.modules) {
    return {
      notFound: true,
    }
  }

  return {
    props: {
      ...getPagePropsFromContext(context),
      builder: getNonNullValues(builder),
      settings: {
        ...getNonNullValues(settings),
        theme: {
          ...getNonNullValues(settings.theme),
          color: getThemeBackgroundColor(settings.theme),
        },
      },
    },
    revalidate: 60,
  }
}

export default HomePage
