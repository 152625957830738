import cx from 'classnames'

import { type SanityBuilderHeader } from '@data/sanity/queries/types/builder'
import { type SanityProfileSettings } from '@data/sanity/queries/types/settings'

import Photo from './photo'
import SocialIcon from './social-icon'

type HeaderProps = SanityBuilderHeader &
  Pick<SanityProfileSettings, 'logo' | 'brandName' | 'socialLinks'>

const Header = ({
  profilePhoto,
  type,
  logo,
  brandName,
  brandDescription,
  socialLinks,
}: HeaderProps) => {
  const isTypeLogo = type === 'logo' || type === 'logoAndText'
  const isTypeText = type === 'text' || type === 'logoAndText'

  return (
    <header
      className={cx('relative', {
        'h-[500px] rounded-b-xl overflow-hidden text-gray-light':
          !!profilePhoto,
      })}
    >
      {!!profilePhoto && (
        <>
          <Photo
            image={profilePhoto}
            width={448}
            height={500}
            fill
            className="absolute inset-0 w-[448px] h-[500px]"
            imageClassName="object-cover"
          />
          <div className="absolute inset-x-0 bottom-0 h-[270px] z-10 pointer-events-none bg-gradient-to-b from-transparent to-gray-dark" />
        </>
      )}

      <div
        className={cx('px-4 flex flex-col', {
          'absolute inset-x-0 bottom-0 py-8 z-20': !!profilePhoto,
          'pt-16 pb-8': !profilePhoto,
        })}
      >
        {isTypeLogo && !!logo && (
          <Photo
            image={logo}
            className="flex justify-center"
            imageClassName="max-w-52"
          />
        )}

        {isTypeText && !!brandName && (
          <h1 className="m-0 mt-2 text-center">{brandName}</h1>
        )}

        {!!brandDescription && (
          <p className="m-0 mt-2.5 text-center">
            {brandDescription.length > 47
              ? `${brandDescription.substring(0, 47)}...`
              : brandDescription}
          </p>
        )}

        {!!socialLinks && socialLinks.length > 0 && (
          <div className="mt-6 flex justify-center items-center gap-6">
            {socialLinks.map(({ _key, socialNetwork, url, email }) => (
              <SocialIcon
                key={_key}
                socialNetwork={socialNetwork}
                url={url}
                email={email}
              />
            ))}
          </div>
        )}
      </div>
    </header>
  )
}

export default Header
