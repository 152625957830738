import { type SanityBuilderQuery } from '@data/sanity/queries/types/builder'
import { type SanityModuleItem } from '@data/sanity/queries/types/modules'
import { type SanitySettingsQuery } from '@data/sanity/queries/types/settings'

import Module from '@modules/module'
import Footer from './footer'
import Header from './header'
import NavigationSlider from './navigation-slider'

import cx from 'classnames'

import { useState } from 'react'

export interface ContentProps {
  builder: SanityBuilderQuery
  settings: SanitySettingsQuery
}

interface ModuleItemsProps {
  items: SanityModuleItem[]
}

interface CycleState {
  isNextItemUsed?: boolean
}

const ModuleItems = ({ items }: ModuleItemsProps) => {
  const cycleState: CycleState = {}

  return items
    .map((item, index) => {
      if (cycleState.isNextItemUsed) {
        cycleState.isNextItemUsed = false
        return
      }

      const nextItem = items[index + 1]

      // Combine product modules into groups of 2
      if (
        !!nextItem &&
        item._type === 'product' &&
        nextItem._type === 'product'
      ) {
        cycleState.isNextItemUsed = true

        return (
          <div key={`${item._key}${nextItem._key}`} className="flex gap-2.5">
            <Module module={item} />
            <Module module={nextItem} />
          </div>
        )
      }

      return <Module key={item._key} module={item} />
    })
    .filter(Boolean)
}

const Content = ({ builder, settings }: ContentProps) => {
  const { header, modules } = builder
  const { privacySettings, profileSettings } = settings

  const [activeModule, setActiveModule] = useState<string>(
    modules?.[0]?._key ?? '',
  )

  const scrollToModule = (moduleKey: string) => {
    const moduleElement = document.getElementById(moduleKey)
    if (moduleElement) {
      moduleElement.scrollIntoView({
        behavior: 'auto',
        block: 'start',
      })
      setActiveModule(moduleKey)
    }
  }

  return (
    <div className="min-h-full flex flex-col gap-10 justify-between sm:rounded-xl bg-pageBackground text-pageText overflow-hidden">
      <Header
        profilePhoto={header.profilePhoto}
        type={header.type}
        logo={profileSettings.logo}
        brandName={profileSettings.brandName}
        brandDescription={header.brandDescription}
        socialLinks={profileSettings.socialLinks}
      />

      {/* <div className="mx-5">
        <NavigationSlider>
          {modules.map(({ _key, heading }) => (
            <button
              key={_key}
              className={cx('rounded-md px-4 py-2.5', {
                'bg-pageText bg-opacity-10 font-medium': activeModule === _key,
              })}
              onClick={() => scrollToModule(_key)}
            >
              {heading}
            </button>
          ))}
        </NavigationSlider>
      </div> */}

      {modules.map(({ _key, heading, description, items }) => (
        <section id={_key} key={_key} className="flex flex-col gap-5 mx-5">
          <div className="flex flex-col gap-y-2">
            {!!heading && <h2>{heading}</h2>}
            {!!description && <p>{description}</p>}
          </div>

          <div className="flex flex-col gap-4">
            <ModuleItems items={items ?? []} />
          </div>
        </section>
      ))}

      <Footer
        customPrivacyPolicy={privacySettings.customPrivacyPolicy}
        customPrivacyPolicyUrl={privacySettings.customPrivacyPolicyUrl}
      />
    </div>
  )
}

export default Content
